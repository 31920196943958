import { rem } from 'polished';
import { ToastContainer as ReactToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastContainer = styled(ReactToastContainer).attrs({
  position: 'bottom-right',
  hideProgressBar: true,
  newestOnTop: true,
})`
  .Toastify__toast {
    --border-color: currentColor;

    padding: ${rem(20)};
    color: ${({ theme }) => theme.colors.palette.black};
    background: ${({ theme }) => theme.colors.palette.white};
    border-left: 8px solid var(--border-color);
    border-radius: ${({ theme }) => theme.borderRadius.small};
    box-shadow: 0 8px 15px 0 rgba(0, 53, 96, 0.15);
  }

  .Toastify__toast--error {
    --border-color: ${({ theme }) => theme.colors.palette.redDark};
  }

  .Toastify__toast--warning {
    --border-color: ${({ theme }) => theme.colors.palette.orange};
  }

  .Toastify__toast--success {
    --border-color: ${({ theme }) => theme.colors.palette.green};
  }

  .Toastify__toast-body {
    color: currentColor;
  }

  .Toastify__progress-bar {
    background: var(--border-color);
    filter: opacity(0.25);
  }

  .Toastify__close-button {
    color: currentColor;
  }
`;
